<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb @on-saveContinue="onFormSubmit(true)" />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <b-card>
                <b-card-body class="p-0">
                  <b-form @submit.prevent="onFormSubmit">
                    <b-card-title> What is your name? </b-card-title>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['fas', 'user']" />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="8"
                          order="3"
                          order-lg="2"
                          class="text"
                        >
                          Your name will be used for your website URL and after
                          publishing it cannot be changed.
                          <span>
                            <span class="font-weight-bold">Profile URL: </span>
                            <a
                              :href="clientPage"
                              class="text-reset text-wrap"
                              target="_blank"
                              ><u>{{ clientPage }}</u></a
                            >
                          </span>
                        </b-col>
                        <b-col
                          cols="9"
                          lg="3"
                          order="2"
                          order-lg="3"
                          class="button"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            v-b-modal.modal-theme-guide
                            @click="nameModal()"
                          >
                            View Example
                            <font-awesome-icon
                              :icon="['far', 'window-maximize']"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-alert :show="!!errorMessage" variant="danger">{{
                      errorMessage
                    }}</b-alert>
                    <b-form-group>
                      <ValidationProvider
                        name="Name"
                        rules="required"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-name"
                          v-model="candidateModel.name"
                          :state="getValidationState(validationContext)"
                          :disabled="contentPublished"
                          placeholder="Enter your full name"
                          v-on:keyup="onNameKeyUp"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                    <b-card-title class="mt-5">
                      What position are you campaigning for?
                    </b-card-title>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['fas', 'briefcase']" />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="8"
                          order="3"
                          order-lg="2"
                          class="text"
                        >
                          Please select the position that you are campaigning
                          for from the dropdown below. This will show below your
                          name on your website.
                        </b-col>
                        <b-col
                          cols="9"
                          lg="3"
                          order="2"
                          order-lg="3"
                          class="button"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            v-b-modal.modal-theme-guide
                            @click="positionModal()"
                          >
                            View Example
                            <font-awesome-icon
                              :icon="['far', 'window-maximize']"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-form-group>
                      <b-form-select
                        v-model="candidateModel.positionId"
                        :options="candidatePositions"
                        @change="$refs.observer.flags.dirty = true"
                      ></b-form-select>
                    </b-form-group>
                    <b-card-title class="mt-5">
                      What photo would you like to add as your headshot?
                    </b-card-title>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['fas', 'portrait']" />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="8"
                          order="3"
                          order-lg="2"
                          class="text"
                        >
                          <p>
                            Photos make your site more personal. You can use the
                            browse button below to choose an image from your
                            computer to be the first thing someone sees on your
                            site. If you do not have one ready you can skip this
                            step for now.
                          </p>
                          <p>
                            It is recommended that you use a portrait photo to
                            best fit the template. If your image is an irregular
                            aspect ratio, landscape, or not centered you can use
                            the crop tool to adjust how the image will display.
                          </p>
                        </b-col>
                        <b-col
                          cols="9"
                          lg="3"
                          order="2"
                          order-lg="3"
                          class="button"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            v-b-modal.modal-theme-guide
                            @click="headshotModal()"
                          >
                            View Example
                            <font-awesome-icon
                              :icon="['far', 'window-maximize']"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-form-group>
                      <ValidationProvider
                        name="Headshot Photo"
                        v-slot="validationContext"
                      >
                        <b-row>
                          <b-col lg="7" :class="hasHeadshotPhoto ? `pr-0` : ``">
                            <b-form-file
                              v-model="headshot"
                              :state="getValidationState(validationContext)"
                              accept="image/*"
                              placeholder="Select a photo"
                              @change.stop="imagePreview"
                            ></b-form-file>
                          </b-col>
                          <b-col lg="5">
                            <b-button
                              v-if="hasHeadshotPhoto"
                              @click="editImage"
                              variant="outline-secondary"
                              >Edit <font-awesome-icon :icon="['fas', 'edit']"
                            /></b-button>
                            <b-button
                              class="ml-2"
                              variant="outline-danger"
                              v-if="hasHeadshotPhoto"
                              @click="resetImages"
                              >Remove
                              <font-awesome-icon :icon="['fas', 'trash-alt']"
                            /></b-button>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                    <b-row>
                      <b-col lg="8">
                        <b-button
                          v-show="false"
                          ref="submit"
                          type="submit"
                          variant="primary"
                          >Submit</b-button
                        >

                        <b-img
                          v-if="imageSrc"
                          :src="imageSrc"
                          fluid
                          alt="Headshot Photo"
                        ></b-img>
                      </b-col>
                    </b-row>
                    <b-card-title class="mt-5">
                      What photo would you like to show on the WGAW Elections Landing Page?
                    </b-card-title>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['fas', 'user-circle']" />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="8"
                          order="3"
                          order-lg="2"
                          class="text"
                        >
                          <p>
                            Your image will be displayed on the homepage of the WGA Election site. 
                            Please select the photo you would like to display. 
                            Once you have uploaded your photo, you can crop it as you like to fit 
                            the rounded display that will appear once your site is published.
                          </p>                          
                        </b-col>
                        <b-col
                          cols="9"
                          lg="3"
                          order="2"
                          order-lg="3"
                          class="button"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            v-b-modal.modal-theme-guide
                            @click="homePageHeadshotModal()"
                          >
                            View Example
                            <font-awesome-icon
                              :icon="['far', 'window-maximize']"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-form-group>
                      <ValidationProvider
                        name="Headshot Photo"
                        v-slot="validationContext"
                      >
                        <b-row>
                          <b-col lg="7" :class="hasHomePageHeadshotPhoto ? `pr-0` : ``">
                            <b-form-file
                              v-model="homePageHeadshot"
                              :state="getValidationState(validationContext)"
                              accept="image/*"
                              placeholder="Select a photo"
                              @change.stop="homePageImagePreview"
                            ></b-form-file>
                          </b-col>
                          <b-col lg="5">
                            <b-button
                              v-if="hasHomePageHeadshotPhoto"
                              @click="editHomePageImage"
                              variant="outline-secondary"
                              >Edit <font-awesome-icon :icon="['fas', 'edit']"
                            /></b-button>
                            <b-button
                              class="ml-2"
                              variant="outline-danger"
                              v-if="hasHomePageHeadshotPhoto"
                              @click="resetHomePageImages"
                              >Remove
                              <font-awesome-icon :icon="['fas', 'trash-alt']"
                            /></b-button>
                          </b-col>
                        </b-row>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                    <b-row>
                      <b-col lg="8">
                        <b-button
                          v-show="false"
                          ref="submit"
                          type="submit"
                          variant="primary"
                          >Submit</b-button
                        >

                        <b-img
                          v-if="homePageImageSrc"
                          :src="homePageImageSrc"
                          rounded="circle"
                          height="50"
                          alt="Home Page Headshot Photo"
                        ></b-img>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-card>
              <AdminFooterButton
                @on-save="onSave"
                @on-back="onBack"
                @on-preview="onPreview"
                @on-skip="onSkip"
                :hideSkipButton="
                  candidateTemp.name || candidateTemp.positionId > 0
                    ? false
                    : true
                "
                :saving="saving"
                :invalid="invalid"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      :theme-id="infoModalId"
      :template-id="candidateModel.profileTheme"
    />
    <b-modal id="modal-crop-image" title="Crop Image" size="lg" hide-footer>
      <b-row>
        <b-col>
          <cropper
            ref="cropper"
            class="cropper"
            :src="imageSrc"
            :stencil-props="{
              aspectRatio: 2 / 3,
              movable: true,
              resizable: true,
            }"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="text-left">
          <b-button type="button" variant="secondary" @click="cancelCrop"
            >Cancel</b-button
          >
        </b-col>
        <b-col class="text-right">
          <b-button type="button" variant="primary" @click="cropImage"
            >Crop</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-crop-homepage-image" title="Crop Image" size="lg" hide-footer>
      <b-row>
        <b-col>
          <cropper
            ref="cropper"
            class="cropper"
            :src="homePageImageSrc"
            :stencil-component="$options.components.CircleStencil"
              :stencil-props="{
                aspectRatio: 1,
                movable: true,
                resizable: true,
              }"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="text-left">
          <b-button type="button" variant="secondary" @click="cancelCropHomePageImage"
            >Cancel</b-button
          >
        </b-col>
        <b-col class="text-right">
          <b-button type="button" variant="primary" @click="cropHomePageImage"
            >Crop</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import DirtyFlagMixin from "@/mixins/dirtyflag.mixin";
import CandidateMixin from "@/mixins/candidate.mixin";
import ModalThemeGuide from "@/components/admin/ModalThemeGuide.vue";
import { Cropper, CircleStencil } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
export default {
  name: "AboutNamePosition",
  components: {
    Cropper,
    CircleStencil
  },
  mixins: [DirtyFlagMixin, CandidateMixin],
  data() {
    return {
      headshot: null,
      homePageHeadshot: null,
      previewSrc: null,
      infoModalId: null,
      imageSrc: null,
      homePageImageSrc: null,
      clientPage: "",
    };
  },
  mounted() {
    let slug = "";
    if (this.candidateModel.slug) slug = this.candidateModel.slug;
    this.clientPage = `${process.env.FRONT_END}/${this.$store.state.settings.item.electionYear}/${slug}`;
  },
  computed: {
    hasHeadshotPhoto() {
      return this.candidateModel.headshotPhotoPath || this.imageSrc;
    },
    hasHomePageHeadshotPhoto() {
      return this.candidateModel.homePageHeadshotPhotoPath || this.homePageImageSrc;
    },
    contentPublished() {
      return this.$store.getters["candidates/candidate"].hasPublishedContent;
    },
  },
  watch: {
    "candidateModel.headshotPhotoPath": {
      deep: true,
      handler() {
        this.imageSrc = this.candidateModel.headshotPhotoPath;
      },
    },
    "candidateModel.homePageHeadshotPhotoPath": {
      deep: true,
      handler() {
        this.homePageImageSrc = this.candidateModel.homePageHeadshotPhotoPath;
      },
    },
  },
  methods: {
    onNameKeyUp(e) {
      this.clientPage = `${process.env.FRONT_END}/${
        this.$store.state.settings.item.electionYear
      }/${this.slugify(this.candidateModel.name)}`;
    },
    slugify(text) {
      if (text) {
        return text
          .toLowerCase()
          .replace(/\s+/g, "-") // Replace spaces with -
          .replace(/&/g, "-and-") // Replace & with 'and'
          .replace(/[^\w-]+/g, "") // Remove all non-word chars
          .replace(/--+/g, "-"); // Replace multiple - with single -
      }
      return "";
    },
    editImage() {
      this.$bvModal.show("modal-crop-image");
    },
    editHomePageImage() {
      this.$bvModal.show("modal-crop-homepage-image");
    },
    cancelCrop() {
      this.$bvModal.hide("modal-crop-image");
    },
    cancelCropHomePageImage() {
      this.$bvModal.hide("modal-crop-homepage-image");
    },
    cropImage() {
      const uploadedFile = this.headshot;
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (uploadedFile) {
            const file = new File([blob], uploadedFile.name, {
              type: uploadedFile.type,
              lastModified: new Date().getTime(),
            });
            this.headshot = file;
          } else {
            var existingImageUrl = this.candidateModel.headshotPhotoPath;
            var filename = existingImageUrl.substring(
              existingImageUrl.lastIndexOf("/") + 1
            );
            const file = new File([blob], filename);
            this.headshot = file;
          }
          const url = URL.createObjectURL(blob);
          this.candidateModel.headshotPhotoPath = url;
          this.imageSrc = url;
        });
      }
      this.$bvModal.hide("modal-crop-image");
    },
    cropHomePageImage() {
      const uploadedFile = this.homePageHeadshot;
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (uploadedFile) {
            const file = new File([blob], uploadedFile.name, {
              type: uploadedFile.type,
              lastModified: new Date().getTime(),
            });
            this.homePageHeadshot = file;
          } else {
            var existingImageUrl = this.candidateModel.homePageHeadshotPhotoPath;
            var filename = existingImageUrl.substring(
              existingImageUrl.lastIndexOf("/") + 1
            );
            const file = new File([blob], filename);
            this.homePageHeadshot = file;
          }
          const url = URL.createObjectURL(blob);
          this.candidateModel.homePageHeadshotPhotoPath = url;
          this.homePageImageSrc = url;
        });
      }
      this.$bvModal.hide("modal-crop-homepage-image");
    },
    headshotModal() {
      this.infoModalId = "headshot";
    },
    homePageHeadshotModal() {
      this.infoModalId = "homepage-headshot";
    },
    nameModal() {
      this.infoModalId = "name";
    },
    positionModal() {
      this.infoModalId = "position";
    },
    resetImages() {
      this.candidateModel.headshotPhotoPath = null;
      this.previewSrc = null;
      this.headshot = null;
      this.imageSrc = null;
    },
    resetHomePageImages() {
      this.candidateModel.homePageHeadshotPhotoPath = null;
      this.homePageHeadshot = null;
      this.homePageImageSrc = null;
    },
    imagePreview(e) {
      // let reader = new FileReader();
      // reader.onload = () => {
      //   this.imageSrc = reader.result;
      // };
      // reader.readAsDataURL(e.target.files[0]);
      const { files } = e.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.candidateModel.headshotPhotoPath) {
          URL.revokeObjectURL(this.candidateModel.headshotPhotoPath);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        this.candidateModel.headshotPhotoPath = blob;
        this.imageSrc = blob;
      }
    },
    homePageImagePreview(e) {
      const { files } = e.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.candidateModel.homePageHeadshotPhotoPath) {
          URL.revokeObjectURL(this.candidateModel.homePageHeadshotPhotoPath);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        this.candidateModel.homePageHeadshotPhotoPath = blob;
        this.homePageImageSrc = blob;
      }
    },
    onPreview() {},
    onBack() {
      this.$router.push("contact-email");
    },
    onSkip() {
      this.$router.push("intro-text");
    },
    onSave() {
      this.$refs.submit.click();
    },
    onFormSubmit(fromBreadcrumb) {
      let payload = {
        userId: this.$route.params.id,
        name: this.candidateModel.name,
        position: this.candidateModel.positionId,
        headshot: this.headshot,
        headshotCurrentUrl: this.candidateModel.headshotPhotoPath,
        homePageHeadshot: this.homePageHeadshot,
        homePageHeadshotCurrentUrl: this.candidateModel.homePageHeadshotPhotoPath
      };
      this.isSubmitting = true;
      this.saving = true;

      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.candidateService
            .SaveNamePositionAsync(payload)
            .then((res) => {
              setTimeout(() => {
                this.$store.commit("candidates/SET_CANDIDATE", res.data);
                this.saving = false;
                if (fromBreadcrumb != true) {
                  this.$router.push("intro-text");
                }
              }, 500);
            })
            .catch((e) => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-wizard {
  .card-body {
    .btn-outline-danger {
      border-width: 0;
    }
  }
}
</style>
